import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { Button } from './Button';
import '@fortawesome/fontawesome-free/css/all.min.css';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
      setClick(false); // Close mobile menu when resizing to desktop
    }
  };

  useEffect(() => {
    showButton();
    window.addEventListener('resize', showButton);
    return () => window.removeEventListener('resize', showButton);
  }, []);

  return (
    <>
      <nav className='navbar font-bold'>
        <div className='navbar_container'>
          <Link to='/' className='navLogo' onClick={closeMobileMenu}>
            <img src='/asura_Logo.jpeg' alt='Asura Logo' />
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav_menu active' : 'nav_menu'}>
            <li className='nav_item'>
              <Link to='/' className='nav_Links' onClick={closeMobileMenu}>
                Home
              </Link>
            </li>
            <li className='nav_item'>
              <Link to='/performances' className='nav_Links' onClick={closeMobileMenu}>
                Performances
              </Link>
            </li>
            <li className='nav_item'>
              <Link to='/sponsor' className='nav_Links' onClick={closeMobileMenu}>
                Support
              </Link>
            </li>
            <li className='nav_item'>
              <Link to='/contact' className='nav_Links' onClick={closeMobileMenu}>
                {<button buttonStyle='btn_outline' to='/contact'>Contact Us</button>}
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
