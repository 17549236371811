import React from 'react';

function Sponsor_intro() {
  const support_description =` As we prepare for an exciting new season, we need your support 
  to ensure we deliver our best performances yet. Competing at a high level comes 
  with significant expenses, and we're seeking donations to help cover these costs. Every 
  contirbution, big or small, helps us meet the high standards our friends, families, and supporters 
  expect while showcasing our talents across the nation. By donating to our GoFundMe or 
  becoming an offical sponsor, you'll directly contirbute to Asura's 17-year legacy 
  of excellence. Our performances aren't just limited to a stage. If you need dancers 
  for any event, we offer our services at a fair price.
  `;
  return (
    
    <div className='sponsor flex items-center'>
        <img src="/17.0_img.png" alt="Sponsor" className="w-80 h-60" />
        <div className='sponsor_information ml-6'>
            <h1 className="text-4xl font-bold text-white pt-5 pb-5">Our History</h1>
            <p className="text-lg text-white text-align-left text-opacity-70">{support_description}</p>
        </div>
    </div>
  );
}

export default Sponsor_intro;
