import React from 'react';
import '../Contact.css';
import Swal from 'sweetalert2'
import { Button } from '../Button';
import Footer from '../Footer';

const Contact = () => {
    const onSubmit = async (event) => {
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(event.target);

        const object = Object.fromEntries(formData);
        const json = JSON.stringify(object);

        try {
            const res = await fetch("http://localhost:3001/contact", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: json
            });

            if (!res.ok) {
                throw new Error(`HTTP error! Status: ${res.status}`);
            }

            const data = await res.json();
            console.log("Success", data);

            if(data.success) {
                Swal.fire({
                    title: "Success!",
                    text: "Your message was sent",
                    icon: "success"
                  });
            }

            form.reset();
        } catch (error) {
            console.error("Fetch error", error);
        }
    };

    return (
       <div className='main_content'>
             <section className="contact font-bold">
            <form onSubmit={onSubmit}>
                <h2>Contact Form</h2>
                <div className='inputs font-bold'>
                    <label>Name</label>
                    <input type="text" className="information" placeholder='Enter your name' name='name' required/>
                </div>
                <div className='inputs font-bold'>
                    <label>Email</label>
                    <input type="email" className="information" placeholder='Enter your email' name='email' required/>
                </div>
                <div className='inputs font-bold'>
                    <label>Message</label>
                    <textarea name='message' className="mainText" placeholder='Enter your message' required/>
                </div>
                <div className='button_cont'>
                <Button type="submit" className='button'>Send Message</Button>
                </div>
                
            </form>
        </section>

        <Footer/>


       </div>
    );
};

export default Contact;