import React from 'react';
import Slider from "react-slick";
import Sponsor_intro from '../Sponsor_intro';
import Footer from '../Footer';
import '../Sponsor_intro.css';
import '../../index.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Sponsor() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1
  };

  return (
    <div className='page-layout'>
      {/* Information Component */}
      <Sponsor_intro />  {/* Add the Information component here */}

      {/* Additional Content Section */}
      <div>
        {/* You can include any other components or HTML elements here */}
      </div>

      {/* Slider Section */}
      <div className='slider-container w-4/6 m-auto mt-20'>
        <Slider {...settings}>
          {info.map((d, index) => (
            <div key={index} className='bg-white h-[400px] text-black rounded-xl'>
              <div className='h-56 rounded-t-xl bg-black flex justify-center items-center'>
                <img src={d.img} alt="" className='h-44 w-44 rounded-full'/>
              </div>
              <div className='flex flex-col justify-center items-center g-4 p-4'>
                <p className='text-black font-bold text-2xl pb-5 pt-10 '>{d.name}</p>
                <p className='text-black font-semibold'>{d.description}</p>
                <a href={d.Link} target="_blank" rel="noopener noreferrer">
                  <button className=''
                    style={{ backgroundColor: '#7F1D1D', color: 'white', fontSize: '1.25rem', padding: '0.25rem 1.5rem', borderRadius: '0.75rem', }}
                  >
                    Learn More
                  </button>
                </a>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <Footer/>
    </div>


  );
}

const info = [
    { name: 'Donate to UGA Asura', img: '/Sponsor_img_3.jpg', description: '', Link: 'https://www.gofundme.com/f/support-uga-asuras-17th-dance-season?qid=18f220afa9199a6f1a12fb41735f583b' },
    { name: 'Become an Official Sponsor', img: '/Sponsor_img_1.jpg', description: '', Link: 'https://docs.google.com/forms/d/e/1FAIpQLScaqwEJmYSYIbVfqIsmNz3s4-iURr2jEM_JKDlKjRNWQtWlJA/viewform' },
    { name: 'Book Us For Your Next Event', img: '/Sponsor_img_2.jpg', description: '', Link: 'https://docs.google.com/forms/d/1Al2n8QZ-VCdEin5KgFR__fvbcBoPVoLnYRZpHrPNVTQ/edit' }
];

export default Sponsor;
