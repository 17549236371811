import React from 'react'
import'../../App.css'
import Main from '../Main'
import CardsComp from '../CardsComp';
import Footer from '../Footer';
import About from '../About';
import FRQ from '../FRQ';

function HomePage () {
    return (
    <>
      <Main />
      <About/>
      <CardsComp/>
      <FRQ/>
      <Footer/>

    </>
    ); 
}

export default HomePage;