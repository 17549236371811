import React ,{useState} from 'react'
import './Footer.css';
import { Link } from 'react-router-dom'


function Footer() {
    const [click, setClick] = useState(false);
    const closeMobileMenu = () => setClick(false);
  return (
    <div className = "foot_container">
      <div className = 'main_content'>
      <img src = '/asura_Logo.jpeg' alt = "main_Logo"></img>
      <h1 className='font-bold text-3xl pb-3'>UGA Asura</h1>
      </div>
      <div className = "footer_components">
      <li className = 'footer_link' >
            <Link to ='/sponsor' className = 'nav_Links' onClick= {closeMobileMenu}>
            Sponsor
            </Link>
          </li>
          <li className = 'footer_link' >
            <Link to ='https://gofund.me/e3ad7be7' className = 'nav_Links' onClick= {closeMobileMenu}>
            Donate
            </Link>
          </li>
          <li className = 'footer_link' >
            <Link to ='/performances' className = 'nav_Links' onClick= {closeMobileMenu}>
            Performances
            </Link>
          </li>
          <li className = 'footer_link' >
            <Link to ='/contact' className = 'nav_Links' onClick= {closeMobileMenu}>
            Contact Us
            </Link>
          </li>
      </div>
      <div className = 'social_media'>
       
        <li className='mr-4 ml-4'>
          <Link to ='https://www.tiktok.com/@ugaasura?lang=en' className = 'nav_Links' onClick= {closeMobileMenu}>
            <img src = '/tiktokLogo.jpeg' alt = 'tiktok' className='social-media-logo'></img>
            </Link>
          </li>
       <li  className='mr-4 ml-4 mt-3'>
          <Link to ='https://www.instagram.com/uga_asura/?hl=en' className = 'nav_Links' onClick= {closeMobileMenu}>
            <img src = '/instaLogo.jpg' alt = 'instagram' className='insta-media-logo'></img>
            </Link>
          </li>
        <li  className='mr-4 ml-4'>
          <Link to ='https://www.youtube.com/@ugaasura/videos' className = 'nav_Links' onClick= {closeMobileMenu}>
            <img src = '/youtubeLogo.jpeg' alt = 'youtube' className='social-media-logo'></img>
            </Link>
          </li>

      </div>
    </div>
  )
}

export default Footer