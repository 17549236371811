import React from 'react';
import { Link } from 'react-scroll'; // Import Link for smooth scrolling

function Main() {
  return (
    <div className='container'>
      <video src="/Asura_Intro.mp4" autoPlay muted loop />
      <h1 className='font-bold'>Welcome to UGA Asura</h1>
      <p className='font-bold'>The University of Georgia's premier Co-ed Bollywood Fusion Dance Team</p>
      <div className='main_btn'>
        {/* "Get Started" button scrolls to another section */}
        <Link to="cards" spy={true} smooth={true} offset={20} duration={500}>
          <button className='btn font-bold btn_outline btn_large'>
            Get Started
          </button>
        </Link>
        {/* "Donate" button links to the GoFundMe page */}
        <a
          href="https://www.gofundme.com/f/support-uga-asuras-17th-dance-season?qid=e7337743f454c4e3dc9e29104c8e9790"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className='btn font-bold btn_outline btn_large hover:bg-red-500'>
            Donate
          </button>
        </a>
      </div>
      
    </div>
  );
}

export default Main;
