
import React from 'react'
import { Link } from 'react-router-dom'

function Card(props) {
  return (
    <>
    <li className = 'card_item'>
        <Link className = "card_link" to = {props.path}>
            <figure className = "card_figure" >
                <img src = {props.src} alt = 'img' className = "card_img"/>
            </figure>
            <div className = "card_info">
              <h5 className = "card_text">
                {props.text}
              </h5>
            </div>
        </Link>
     </li>
    </>
  )
}

export default Card