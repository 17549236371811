import { useState} from 'react';
import './Main.css';
import '../App.css';
import'./FRQ.css';


function FRQ() {

    const [clicked, setClick] = useState(null)

    const change = (i) => {
        if (clicked === i) {
            return setClick(null)
        }

        setClick(i)

    }

    

    return (
    <div className='FRQ_Section'>
        <hr className='solid_line opacity-15' />
        <h1 className='text-3xl font-bold pt-5 pb-5'> Frequently Asked Questions</h1>
    <div className='FRQ'>
        <div className='attributes'>
        {questions.map((object, i) => (
    <div className='object'>
        <div className='question font-bold' onClick={() => change(i)}>
            <h2>{object.question}</h2>
            <span>{clicked === i ? '-' : '+'}</span>
        </div>
        <div 
            className={clicked === i ? 'answer_display' : 'answer'} 
            style={{ opacity: clicked === i ? 0.7 : 1 }}
        >
            {object.answer}
        </div>
    </div>
))}
        </div>
    </div>
    </div>
    );
}

const questions = [
    {
    question: 'How long are practices?',
    answer: 'Practices will be held on Monday, Tuesday, and Thursday from 8-11pm for weeks where there is not a competition. For competition weeks, practices will be held Monday-Thursday from 8-11pm. The team will then travel to the competition on Friday and perform on Saturday.'
    },
    {
    question: 'When is competition season?',
    answer: 'Competitions will take place during both Fall and Spring semesters. Fall competitons fall in the month of November while Spring competitions will happen from January through March.'
    },
    {
    question: 'How many times will a team compete?',
    answer: 'The minimum number of competitions a team must attend is 2 in order to be eligible for Legends. The standard amongst most teams is 4 competitions with some teams competing in 5 competitions.'
    },

]
   


export default FRQ