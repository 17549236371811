import React from 'react'
import Card from './Card'
import './Cards.css';

function CardsComp(props) {
  return (
    <div className = "cards" id = "cards">
    
      <div className = "card_container">
       <div className = "card_wrap">
           <ul className = "card_item font-bold ">
               <Card 
               src = "/clock_img.jpg" 
               text = 'See All the Ways You Can Help Asura This Upcoming Season'
              
               path = '/sponsor'
                />
              <Card 
               src = "/classical_img.jpg" 
               text = "Check Out Asura's Performances Throughout the Years"
              
               path = '/performances'
                />
           </ul>
           <ul className = "card_item font-bold">
               <Card 
               src = "/16.0.jpg" 
               text = 'Help Asura Compete This Upcoming Season'
          
               path = 'https://gofund.me/e3ad7be7'
                />
              <Card 
               src = "/comedy_img.jpg " 
               text = 'Any Questions? Contact Asura Here'
             
               path = '/contact'
                />
           </ul>
       </div>
      </div>
    </div>
  );
}

export default CardsComp
