import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import HomePage from './components/SitePages/HomePage';
import Performances from './components/SitePages/Performances';
import Contact from './components/SitePages/Contact';
import Sponsor from './components/SitePages/Sponsor';
import Navbar from './components/Navbar';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' exact element={<HomePage />} />
          <Route path='/performances' element={<Performances/>} />
          <Route path='/contact' element={<Contact/>} />
          <Route path='/sponsor' element={<Sponsor/>} />
        </Routes>
      </Router>
    </>
  );
}

export default App;